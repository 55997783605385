import { IResourcesLoaderSource } from '../../loaders/ResourceLoader';

export default class Resources {
	public static data: Array<IResourcesLoaderSource> = [
		{
			name: 'promotionImage',
			source: '/assets/images/teasers/autumn-teaserimage.jpg' //bg-image-upcoming@2x.jpg'
		}
		// // ,
		// {
		// 	name: 'promotionVideo',
		// 	source: '/assets/video/bproautumn2023.mp4'
		// }
	];
}
